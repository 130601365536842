.leaders-page {
    width: 80%;
    background-color: rgba(14, 14, 14, 0.8);
    margin-top: 70px;
    padding: 20px;
    margin-bottom: 70px;
    border-radius: 5px;
    color: white;
}
.leaders-page h1 {
    font-size: 2.25rem;
    font-weight: 600;
}

.leader-list { display: flex; align-items: stretch; justify-content: center; gap: 24px; list-style: none; padding: 0; flex-wrap: wrap }

.leaders-filter { margin: 24px 0 }
.leaders-filter > ul { margin: 0; list-style: none; padding: 0; display: flex; align-items: center; justify-content: center; gap: 18px }
.leaders-filter > ul > li { border: 2px solid rgba(255,255,255,0.4); padding: 6px 12px; font-weight: 500; border-radius: 12px; cursor: pointer }
.leaders-filter > ul > li:hover { background: rgba(255,255,255,0.2) }
.leaders-filter > ul > li.selected { border-color: #b48a5f; background: #b48a5f }

.leader { background: #b48a5f; border-radius: 6px; padding: 6px; height: 100%; box-sizing: border-box; width: 400px; gap: 6px; display: flex; flex-direction: column }
.leader-header { display: flex; align-items: center; flex-direction: column }
.leader-main { background: #c7b492; border-radius: 4px; color: #534032; flex-grow: 1; font-weight: 500 }
.leader-main strong { font-weight: 900 }
.leader-title { text-transform: uppercase; padding: 0 12px; text-align: left; letter-spacing: -1px; font-size: 24px; line-height: 30px; margin: 0; text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 2px 0 #000; font-weight: 900 }

.leader-icon { display: flex; align-items: flex-end; background: #444; border: 1px solid #b48a5f; margin: 18px auto 18px; border-radius: 3px; width: 128px; height: 128px; position: relative }
.leader-icon > img { position: absolute; bottom: 0; display: block; width: 100%; max-height: 154px }
.leader-powers { margin: 18px 0; padding: 0 18px; color: #906a43; font-weight: bold; text-transform: uppercase }
.leader-powers:after { content: ''; display: block; border-bottom: 1px solid #b48a5f; margin-top: 9px }

.power { margin: 18px 0 }
.power-description { padding: 0 18px; text-align: left }
.power-description p { margin: 9px 0 }
.power-header { margin: 9px 0; display: flex; align-items: center; gap: 9px; padding: 0 18px }
.power-icon { display: block; border: 1px solid #b48a5f; border-radius: 3px; width: 48px; height: 48px }
.power-name { color: #906a43; font-weight: bold; text-transform: uppercase }