.relics-page {
    width: 80%;
    background-color: rgba(14, 14, 14, 0.8);
    margin-top: 70px;
    padding: 20px;
    margin-bottom: 70px;
    border-radius: 5px;
    color: white;
}
.relics-page h1 {
    font-size: 2.25rem;
    font-weight: 600;
}

.relic-list { display: flex; align-items: stretch; justify-content: center; gap: 24px; list-style: none; padding: 0; flex-wrap: wrap }

.relics-filter { margin: 24px 0; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 24px }
.relics-filter-group { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 8px }
.relics-filter-group-title { font-weight: bold; color: #bbb }
.relics-filter-group > ul { margin: 0; list-style: none; padding: 0; display: flex; flex-wrap: wrap; align-items: stretch; justify-content: center; gap: 8px }
.relics-filter-group > ul > li { border: 2px solid rgba(255,255,255,0.4); padding: 6px 12px; font-weight: 500; border-radius: 12px; cursor: pointer }
.relics-filter-group > ul > li:hover { background: rgba(255,255,255,0.2) }
.relics-filter-group > ul > li.selected { border-color: #b48a5f; background: #b48a5f }

.relic { background: #b48a5f; border-radius: 6px; padding: 6px; height: 100%; box-sizing: border-box; width: 400px; gap: 6px; display: flex; flex-direction: column }
.relic-header { display: flex; align-items: center }
.relic-main { background: #c7b492; border-radius: 4px; color: #534032; flex-grow: 1; font-weight: 500 }
.relic-main strong { font-weight: 900 }
.relic-icon { display: block; border: 1px solid #000; border-radius: 3px; width: 90px; height: 60px }
.relic-title { text-transform: uppercase; padding: 0 12px; text-align: left; letter-spacing: -1px; font-size: 24px; line-height: 30px; margin: 0; text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 2px 0 #000; font-weight: 900 }

.relic-ability { margin: 18px 0; padding: 0 18px; color: #906a43; font-weight:bold; text-transform: uppercase }
.relic-ability:after { content: ''; display: block; border-bottom: 1px solid #b48a5f; margin-top: 18px }
.relic-ability > ul { list-style: none; margin: 0; padding: 0 }
.relic-ability > ul > li { display: flex; align-items: center; gap: 6px }
.relic-ability .key:after { content: ':' }

.relic-values { margin: 18px 0; padding: 0 18px; color: #906a43 }
.relic-values > ul { list-style: none; margin: 0; padding: 0 }
.relic-values > ul > li { display: flex; align-items: flex-start; gap: 0; text-align: left; flex-wrap: wrap; margin-bottom: 12px; }
.relic-values > ul > li > * { width: 100% }
.relic-values .key { font-weight:bold; text-transform: uppercase; white-space: nowrap }
.relic-values .key:after { content: ':'; margin-right: 6px }
.relic-values .value { overflow-wrap: anywhere; flex-grow: 1; min-width: 50% }
.relic-values .value .game-value { white-space: initial }
.relic-values .game-value { font-weight: 500; color: rgb(83, 64, 50) }

.relic-description { padding: 0 18px; text-align: left }
.relic-upgrades { padding: 0 18px }
.relic-upgrade { margin: 18px 0; text-align: left }
.relic-upgrade-type { font-weight: bold; text-transform: uppercase }

.game-value { font-weight: bold; white-space: nowrap }
.game-value:before { background-color: currentColor; mask-size: contain; display: inline-block; margin: 0 3px 2px 0; width: 16px; height: 16px; vertical-align: middle }
.game-value-time:before { content: ''; mask-image: url('icons/legend/time.svg') }
.game-value-radius:before { content: ''; mask-image: url('icons/legend/radius.svg') }
.game-value-damage:before { content: ''; mask-image: url('icons/legend/damage.svg') }